import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledButton = styled.button`
  color: ${props => (props.loading ? "transparent" : "#fff")};
  pointer-events: ${props => (props.loading ? "none" : "auto")};
  background-color: #474379;
  border-color: #474379;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 1.75rem;
  font-size: 0.9375rem;
  line-height: 1.84615385;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 0.03em;
  font-size: 0.8125rem;
  min-width: 2.375rem;
  transition: all 300ms ease-in-out;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
  outline: 0;
  position: relative;
  &:hover {
    opacity: 0.8;
  }

  &:active {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }

  &:after {
    content: "";
    animation: ${rotate} 500ms linear infinite;
    border: 2px solid #fff;
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    height: 1.4em;
    width: 1.4em;
    position: absolute;
    left: calc(50% - (1.4em / 2));
    top: calc(50% - (1.4em / 2));
    transform-origin: center;
    position: absolute;
    display: ${props => (props.loading ? "block" : "none")};
  }
`

const Button = props => {
  return <StyledButton {...props} />
}

Button.propTypes = {
  loading: PropTypes.any,
}

export default Button
